<template>
  <v-container fill-height>
    <v-slide-y-transition
      mode="out-in"
      md12
    >
      <v-layout
        wrap
        align-center
        justify-center
      >
        <v-flex
          md12
          sm12
          xs12
        >
          <v-card
            text
            height="100%"
            xtile
          >
            <v-flex cols="12" class="grey darken-3 ma-0 pa-6 align-center justify-center text-center">
              <v-img :src="background" width="100%" max-height="125px" max-width="472px" style="margin: auto"/>
            </v-flex>
            <v-card-title primary-title>
              <v-layout
                column
                justify-top
              >
                <v-flex md5>
                  <span class="headline">Sign Up for {{title}}</span>
                  <v-progress-linear
                    v-if="processing"
                    :indeterminate="true"
                    class="my-4"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-card-title>

            <v-divider />

            <v-form
              ref='signUpForm'
              v-if="!processing"
            >

              <v-card-text class="py-0 px-4">
                <v-alert
                  :value="errorText"
                  class="mt-2"
                  color="error"
                  v-if="errorText.length > 0 "
                >
                  {{errorText}}
                </v-alert>
                <v-layout
                  column
                  justify-top
                >
                  <v-flex md5>
                    <v-text-field
                      v-model="email"
                      label='Email Address'
                      :rules="[v=> !!v || 'Please enter your email address']"
                      disabled
                    />
                    <v-text-field
                      v-model="password"
                      label='Password'
                      :type="'password'"
                      required
                      data-vv-name='password'
                      :rules="[v=> !!v || 'Please enter your password']"
                    />
                    <v-text-field
                      v-model="confirmPassword"
                      label='Confirm Password'
                      :type="'password'"
                      required
                      data-vv-name='confirmPassword'
                      :rules="[v=> !!v || 'Please re-enter your password to confirm it is correct']"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!processing"
                  @click.prevent="submit"
                  text
                  color="primary"
                  type="submit"
                >Sign Up</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import SelectFunctions from '../select/SelectFunctions';
import background from '@/assets/background.png';

export default {

  mounted: function ()
  {
    console.log('Mounted signup component');
    console.log('here!');

    // console.log("RandomBackground: ",this.randomBackground())

    let base64 = this.$route.params.base64;
    let decoded = atob(base64);
    let split = decoded.split("|");

    console.log(base64, decoded, split);
    
    this.email = split[0];
    this.verificationCode = split[1];
  },
  data: () => ({
    email: '',
    password: '',
    confirmPassword: '',
    processing: false,
    _csrf: '',
    loginError: false,
    errorText: '',

    verificationCode: '',

    title: process.env.VUE_APP_APP_TITLE,

    background

  }),
  methods: {
    async submit()
    {
      if(!(this.email && this.password && this.confirmPassword))
      {
        this.errorText = "Missing parameters. Please ensure all fields are filled before submitting this form.";
        return;
      }
      else if(this.password != this.confirmPassword)
      {
        this.errorText = "Your password and confirmation password do not match. Please retype both to ensure you have the correct password.";
        return;
      }
      this.processing = true;
      this.errorText = '';
      var promise = new Promise((resolve, reject) =>
      {
        let params =
        {
          email: this.email,
          password: this.password,
          confirmPassword: this.confirmPassword,
          verification: this.verificationCode,
        };
        console.log(JSON.stringify(params));
        SelectFunctions.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'signup',
          {
            method: 'POST', credentials: 'include',
            headers:
            {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
          })
          .then(async result =>
          {
            if (result.ok)
            {
              resolve(result);
            }
            else
            {
              this.processing = false;
              let data = await result.json();
              let errorText = 'Sorry, the credentials you supplied could not be used. Please try again';

              switch(data.status)
              {
                case "MISSING_PARAMETER":
                  errorText = "One or more fields was missing. Please ensure all fields have been filled.";
                  break;
                case "MISMATCHED_CONFIRM":
                  errorText = "The confirmation password does not match the original password. Please retype both and try again."
                  break;
                case "BAD_VERIFICATION":
                  errorText = "The verification code you have used is invalid or expired."
                  break;
                case "EXPIRED_TOKEN":
                  errorText =  "The verification code has expired. Please ensure you have used your latest invitation email, and that you have used the link within 48 hours."
                  break;
              }

              this.errorText = errorText;
            }
          })
          .catch(error =>
          {
            reject(error);
          });
        });
        promise.then(() =>
        {
          //this.$router.push("/");
          this.$notify({group: "global", type: "success", title: "Sign Up Successful!", text: "Your password has been set successfully. You will be redirected to the login screen in a few seconds."});
          setTimeout(() => {
            this.processing = false;
            window.location.href = '/login/' + this.email;
          }, 5000);
        }, (error) =>
        {
          this.processing = false;
          if (error == '401 ERROR')
          {
            this.errorText = 'Sorry, the credentials you supplied could not be used. Please try again';
          }
          else
          {
            this.errorText = error;
          }
        });
    }
  }
};
</script>